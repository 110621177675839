<template>
  <v-layout d-flex column class="fw fh "  >
    <v-flex shrink>
      <v-toolbar color="transparent">
        <span v-if="selectedTable">
          <v-icon v-if="selectedTable.type == 'rect'" size="28" class="mx-1">mdi-rectangle</v-icon>
          <v-icon v-else class="mx-1">mdi-{{selectedTable.type}}</v-icon>
          <b>( {{selectedTable.number}} )</b>
          <span> {{selectedTable.name}} </span>
        </span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="selectedTable"
          color="success"
          rounded
          depressed
          @click="edit"
        >
          <v-icon class="mx-1" >mdi-account</v-icon>
          {{ 'table_guests' | t }}

        </v-btn>
      </v-toolbar>
    </v-flex>
    <v-flex grow ref="canvas" class=" d-flex justify-center text-center relative">
      <v-overlay absolute >
        <v-sheet id="svg-canvas" color="transparent" class="overflow-x-auto relative" ref="sheet" >
           <v-overlay absolute color="transparent" v-if="!tables.length">
             <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
             
             
             <v-sheet rounded class="pa-6" light id="file-sketch"  v-else-if="!tables_map_file">
               <v-alert class="ma-0" :color="(uploadLoading || completed) ? 'success': 'warning'" text :icon="completed ? 'mdi-file-check-outline' : 'mdi-file-upload-outline'" >
                 {{ uploadLoading ? 'uploading_file_please_wait': (completed ? 'uploaded_done' : 'upload_your_sketch') | t }}
               </v-alert>
               <v-progress-linear :value="progress" :indeterminate="!progress" v-if="uploadLoading && !completed"></v-progress-linear>
                <v-file-input
                    v-else-if="!completed"
                    rounded
                    clearable
                    filled
                    @click:clear="clear"
                    :label="$t('select_an_image')"
                    accept="image/*, .pdf"
                    @change="handelFile"
                    :error="!!error"
                    :error-messages="error"
                    persistent-hint
                    :hint="$t('allowed_files', 'image/*, .pdf')"
                ></v-file-input>
             </v-sheet>
             <v-sheet v-else light>
               <v-icon class="ma-2" color="info" x-large>mdi-map-clock-outline</v-icon>
               <div class=" info--text">

                 {{ 'sketch_waitin' | t }}
               </div>

             </v-sheet>
           </v-overlay>
        </v-sheet>
      </v-overlay>
    </v-flex>
     <v-flex shrink>
      <v-toolbar color="transparent">

        <span v-if="selectedTable">
          <v-icon class="mx-1">mdi-chair-rolling</v-icon>
          
          <span class="caption">
            {{ 'empty_seats' | t }} 
          </span>
          <b>( {{selectedTable.seats - selectedTable.taken }} )</b>
          <v-icon class="mx-1">mdi-account</v-icon>
          <span class="caption">
            {{ 'seated_seats' | t }} 
          </span>
          <b>( {{selectedTable.taken}} )</b>
        </span>
          <span v-else-if="tables.length">
          {{ 'table_not_selected' | t }}
        </span>
      </v-toolbar>
    </v-flex>
  </v-layout>
</template>

<script>
const ratio = 1.4142 // A4 ratio
const A4 = [3508 , 2480]
 
import { SVG } from '@svgdotjs/svg.js'
const padding = 24
export default {
  data: () => ({
    loading: false,
    draw: null,
    tables: [],
    defs: {},
    selected: null,
    tables_map_file: null,
    error: '',
    progress: 0,
    uploadLoading: false,
    completed: false
  }),
  watch: {
    breakpoint: {
      handler () {
        const { canvas, sheet } = this.$refs
         
        if (!this.draw || !canvas) return

        const { height:h, width:w } = canvas.getBoundingClientRect()

        const width = Math.ceil(h * ratio) - (padding * 2)
        const height = h - (padding * 2)
        sheet.$el.style.maxWidth = w + 'px'

        this.draw.size(width, height)
      },
      deep: true
    },
    selected (selected, old) {
      if (!old) return
      old.stroke({ width: 1 })
    },
    selectedTable (table) {
      if (!table) return
      this.selected.stroke({ width: 6 })
    }
  },
  computed: {
    selectedTable () {
      if (!this.selected) return null
      const table = this.tables.find(e => e.id == this.selected.id())
      return table && table.type != 'spacer' ? table : null
    }
  },
  methods: {
    async edit () {
      const vm = this
      if (!vm.selectedTable) return
      const guestsCollection = vm.database.collection('users/'+vm.currentUser.uid+'/guests')
      if (vm.selectedTable.taken && !vm.selectedTable.guests) {
        const ref  = await guestsCollection.where('table.id', '==', vm.selectedTable.id).get()
        vm.selectedTable.guests = ref.docs.map(e => ({id:e.id, ...e.data()  }))
      }
        
      vm.openDialog('guests', { table: {...vm.selectedTable} }, async (guests) => {
          if (!guests.length) return
          const oldGuestIds = vm.selectedTable.guests ? vm.selectedTable.guests.map(e => e.id) : []
          const newGuestIds = guests.map(e => e.id)
          const guestOutOfTable = oldGuestIds.filter( id => !newGuestIds.includes(id) ) 

         
          const taken = guests.map(e => e.guests).reduce((a,b) => a+b)
          const update = () => {
            vm.selectedTable.guests = guests
            vm.selectedTable.taken = taken
            // rerender element

            vm.draw.children().each(function () {
              if ( this.id() ==  vm.selectedTable.id) {
                this.remove();
                vm.addTableToCanvas(vm.selectedTable)
              }
            })
          }
          
          const { name, number, id } = vm.selectedTable
          const batch = vm.database.batch()
          guests.forEach(guest => {
            const doc = guestsCollection.doc(guest.id)
            batch.update(doc, {
              table: { name, number, id }
            })
          })


          const tableDoc = vm.database.collection('users/'+vm.currentUser.uid+'/tables').doc(vm.selectedTable.id)
          batch.update(tableDoc, { taken })


          guestOutOfTable.forEach(id => {
            batch.update(guestsCollection.doc(id), { table: null })
          })  

          vm.dialogLoading(true)
          batch.commit().then(() => {
              update()
              vm.closeDialog()
              vm.notify(vm.$t(`update_successfuly`), {
                  color: 'success',
                  icon: 'mdi-check-circle'
              })
          }).catch(vm.handelError).finally(() => vm.dialogLoading(false))
            

      }, { maxWidth: 650, scrollable:true })
    },
    clear () {

    },
    handelFile (file) {
          const fileMime = file.name.split('.')[1]
          this.uploadLoading = true
          console.log(file);
          const uploadTask = this.upload(file, `users/${this.currentUser.uid}/tables_map.`+fileMime, { contentType: file.type })


                uploadTask.on('state_changed', 
                    (snapshot) => {
                        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    }, 
                    this.handlerError, 
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            this.progress = 0
                            
                            this.database.collection('users').doc(this.currentUser.uid).set({
                              tables_map_file: downloadURL
                            }, { merge: true }).finally(() => {
                              this.uploadLoading = false
                              this.completed = true
                              setTimeout(() => {
                                this.tables_map_file = downloadURL
                              }, 1000)
                            })
                        });
                    }
                );

    },
    addTableToCanvas (table) {
      
        const vm = this
        const { draw } = vm
        const { type, transform } = {...table}

        const group = draw.group().translate(transform.x, transform.y).rotate(transform.rotate).id(table.id)
        console.log(group);
        if (type != 'spacer')
          group.addClass('pointer')
        const groupInner = draw.group().attr({  'stroke-linecap': "round" }) 
        
      
        const el = ['square', 'rect', 'spacer'].includes(type) ? draw.rect().size(transform.w, transform.h) : draw.circle().radius(transform.w/2).cx(transform.w/2).cy(transform.h/2)

        group.on('mousedown', function () { 
          vm.selected = this
        })

  

       
       
         
    
       
 
       
       const rect = el.stroke(type == 'spacer' ? { color: '#888888', opacity: 0.8,  width: 3 } :'#38b6ff') 
          .fill(type == 'spacer' ? this.defs.pattern : '#eeeeee').attr({ rx: 5 })
        groupInner.add(rect)
        
        
        
        
          const title = draw.plain(table.name).translate( transform.w / 2, transform.h/2).attr({ name: 'name' })
          title.font({anchor: 'middle', size: 50,});
          groupInner.add(title)
     
        if (type != 'spacer') {
          const number = draw.plain(table.number).translate(transform.w / 2, 90).attr({ name: 'number' })
           number.font({anchor: 'middle', size: 100,});
          groupInner.add(number)
          const seats = draw.plain(table.taken+'/'+table.seats).translate((transform.w / 2), 87*3).attr({ name: 'seats' })
          seats.font({anchor: 'middle', size: 50,});
          groupInner.add(seats)
        } 
        
        
        group.add(groupInner)
        return group
    },
  },
  async mounted () {
    const vm = this
    const { canvas, sheet } = this.$refs
    this.loading = true    
    let { height:h, width:w } = canvas.getBoundingClientRect()
    const width = Math.ceil(h * ratio) - (padding * 2)
    const height = h - (padding * 2)
    sheet.$el.style.maxWidth = w + 'px'

    this.draw = SVG()
    .addTo('#'+sheet.$attrs.id)
    .attr({ style: 'background: #fff; ', class: 'elevation-1'})
    .size(width, height).viewbox(0, 0, A4[0], A4[1])
    

    this.draw.on('click', function (ev) {
      if(ev.target === this.node) vm.selected = null;
    })

    this.$set(this.defs, 'pattern', this.draw.pattern(10, 10, function(add) {
      add.line(0,0,0,10).stroke({ color: '#888888',  width: 3 })
    }).attr({patternTransform: 'rotate(45)'}))

    const snap = await this.database.collection('users').doc(this.currentUser.uid).get() 
    if (!snap.exists) return 
    const { tables_map_file } = snap.data()
    this.tables_map_file = tables_map_file

    const table = await this.database.collection('users/' + this.currentUser.uid + '/tables').get()
    this.loading = false
    this.tables = table.docs.map((e) => ({ id:e.id, ...e.data() }))

    
    
    this.tables.forEach(table => this.addTableToCanvas(table))
  
  }
}
</script>

<style lang="scss">
#file-sketch {

  .v-alert--text:before{
   background: transparent; 
  }
}
.pointer {
  cursor: pointer;
}
</style>